<template>
  <Modal class="mobile-s" ref="modal" @mounted="modalOpen" @close="modalClose" :settings="settings">
    <div class="post-review-confirm" dpadding>
      <div class="title">
        <span>{{ $locale["post"]["admin-review-confirm"] }}</span>
        <span dcolor status>{{ $locale["words"][postReview.status] }}</span>
      </div>
      <div class="postReviewMessage" v-if="postReview.status === 'disapproved'">
        <Spacer num=".75" />
        <textarea dinput v-model="message" :placeholder="$locale['message']" rows="3"></textarea>
      </div>
      <div class="options">
        <a class="options-button __cancel" @click="modalClose">{{ $locale["words"]["cancel"] }}</a>
        <a :class="`options-button __confirm ${$loading ? ' noevents' : ''}`" @click="sendReview">
          <span v-if="$lockapp || $loading"><iconic name="gspinner" /></span>
          <span v-else>{{ $locale["words"]["confirm"] }}</span>
        </a>
      </div>
    </div>
  </Modal>
</template>

<script>
export default {
  components: {
    Modal: () => import("../widgets/Modal.vue"),
  },
  data: function() {
    return {
      message: "",
      settings: {
        opened: true,
        class: "view menu bottom mk_head",
        animation: "fadeIn",
      },
    };
  },
  methods: {
    modalOpen: function() {
      this.$refs.modal.open();
    },
    modalClose: function() {
      this.$store.state.posts.postReview = null;
    },
    reviewResponse: function({ data: { success, message } }) {
      this.isLoading({ state: false, lockapp: false });
      if (success) {
        this.$store.state.posts.postReview.reviewSuccess = true;
        this.modalClose();
      } else {
        return this.ialert.go({ type: "error", title: "Error", message });
      }
    },
    sendReview: function() {
      try {
        this.isLoading({ state: true, lockapp: true });
        const formData = { post: this.postReview.post._id, status: this.postReview.status, message: this.message };
        this.$store.dispatch("sendReview", { formData, callback: this.reviewResponse });
      } catch (error) {
        this.reviewResponse({ data: { success: false, message: error.message } });
      }
    },
  },
  computed: {
    postReview: function() {
      return this.$store.state.posts.postReview;
    },
  },
};
</script>

<style lang="scss">
.post-review-confirm {
  text-align: center;
  .title {
    font-size: 120%;
    padding: 0 0 $mpadding/2 0;
    [status] {
      margin: 0 0 0 $mpadding/2;
    }
  }
  .options {
    @include Flex(inherit, space-evenly, center);
    padding: $mpadding 0 0 0;
    gap: $mpadding;
    &-button {
      @include Flex(inherit, center, center);
      cursor: pointer;
      flex: 1;
      padding: $mpadding;
      border-radius: $mradius/2;
      user-select: none;
      &.__cancel {
        background-color: $alto;
      }
      &.__confirm {
        background-color: $primary_color;
        color: #fff;
      }
      &:active {
        @include d-active;
      }
    }
  }
}
</style>
